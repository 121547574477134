<template>
  <div class="container">
    <div v-if="showTopBar" class="top-bar">请点击右上角"..."，选择在浏览器中打开进行下载</div>
    <img class="top-img" src="../assets/images/top-img.jpg" alt="">
    <div class="reg-box">
      <div class="reg-title">立即下载</div>
      <div class="reg-content">
        <div class="logo-box">
          <img class="logo" src="../assets/images/logo.png" alt="">
          <div class="logo-text">{{ appName || '--' }}</div>
        </div>
        <div class="btn-box">
          <button class="button" @click="handleDownload">立即下载</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {onBeforeMount, reactive, ref, toRefs} from 'vue'
import axios from 'axios'
import { isWeixin } from '@/utils/util'
import Toast from '@/utils/toast'
import { useRoute } from 'vue-router'

export default {
  name: 'Download',
  setup() {
    const route = useRoute()
    const type = ref('QIANMIAO')
    const state = reactive({
      // baseApi: 'http://192.168.31.128',
      baseApi: 'https://dapi.kuaiguiqx.com',
      url: '',
      showTopBar: false,
      appName: ''
    })

    const getLatestApk = () => {
      axios.get(state.baseApi + '/driver/apk_version/apk', {
        params: {
          type: type.value
        }
      }).then(res => {
        if (res.status === 200 && res.data.code === 0) {
          const data = res.data.data
          state.url = data.uri
          state.appName = data.app_name || data.update_des
        }
      })
    }

    const handleDownload = () => {
      if (isWeixin()) {
        let u = navigator.userAgent
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
        if (isAndroid) {
          Toast('请在浏览器中打开')
        }
        if (isIOS) {
          Toast('请使用安卓设备，在浏览器中打开下载')
        }
      } else {
        window.location.href = state.url
      }
    }

    onBeforeMount(() => {
      state.showTopBar = !!isWeixin();
      if (route.query.type) {
        type.value = route.query.type
      }
      getLatestApk()
    })

    return {
      ...toRefs(state),
      getLatestApk,
      handleDownload
    }
  }
}
</script>

<style>
@import '//at.alicdn.com/t/font_2935912_ancag0zauqh.css';

* {
  outline: none;
}

html, body {
  margin: 0;
  padding: 0;
  outline: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.flex-between-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-left-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.container {
  min-height: 100vh;
  background-color: #fda158;
  padding-bottom: 30px;
  box-sizing: border-box;
}

.container .top-bar {
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background-color: #363636;
  color: #fff;
  font-size: 14px;
}

.container .top-img {
  width: 100%;
  min-height: 397px;
}

.reg-box {
  position: relative;
  margin-top: 20px;
  width: 94%;
  left: 3%;
  border-radius: 6px;
  background-color: #fbf0e6;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 40px;
}

.reg-box .reg-title {
  position: absolute;
  top: -17px;
  width: 143px;
  height: 33px;
  line-height: 33px;
  font-weight: bold;
  color: #fff;
  left: 50%;
  margin-left: -71px;
  background-image: url("../assets/images/title-bg.png");
  background-size: cover;
}

.reg-content {
  margin-top: 10px;
}

.logo-box {
  margin-bottom: 20px;
}

.logo-box .logo {
  width: 80px;
  height: 80px;
  border-radius: 15px;
}

.logo-box .logo-text {
  font-size: 14px;
}

.btn-box {
  width: 40%;
  margin: 0 auto;
}

.btn-box .button {
  border: 0;
  background: #fd745e;
  width: 100%;
  height: 45px;
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  outline: none;
}

.btn-box span {
  display: block;
  padding-top: 10px;
  font-size: 14px;
  color: #999;
}

</style>
